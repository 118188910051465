.header {
  border-bottom: 1px solid #FFFFFF;

  .section-header {
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;

    .nav {
      transition: 1s;

      .nav-list {
        display: flex;
        gap: 32px;

        a, li {
          color: #ABB2BF;

          &:hover {
            color: #FFFFFF;
            transition: 0.5s;
          }
        }
      }
    }

    .burger-btn {
      display: none;
    }

    @media (max-width: 580px) {
      & {
        position: fixed;
        background-color: #282C33;
        z-index: 10;
        border-bottom: 1px solid #FFFFFF;
      }
      .burger-btn {
        display: block;
        position: relative;
        height: 20px;
        width: 24px;
        transition: 0.2s;

        span {
          &, &:before, &:after {
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            width: 100%;
            height: 2px;
            background-color: #D9D9D9;
            transition: 0.2s;
            border-radius: 10px;
          }

          &:after, &:before {
            content: '';
          }

          &:before {
            transform: translateY(-6px);
          }

          &:after {
            transform: translateY(6px);
          }
        }
      }
      .burger-btn-active span:before {
        transform: rotate(45deg);
      }
      .burger-btn-active span:after {
        transform: rotate(-45deg);
      }
      .burger-btn-active span {
        height: 0;
      }
      .nav {
        position: absolute;
        top: 82px;
        transform: translateX(-150%);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 100px;

        .nav-list {
          flex-direction: column;
          padding-top: 20px;
          padding-left: 20px;
          font-size: 32px;
        }
      }
      .nav-active {
        position: fixed;
        transform: translateX(0);
        left: 0;
        background-color: #282C33;
      }
    }

  }
}
