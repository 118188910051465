.study-item {
  border: 1px solid #FFFFFF;
  width: 280px;
  height: max-content;
  padding-bottom: 20px;

  img {
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
    height: 250px;
  }

  h3 {
    border-bottom: 1px solid #FFFFFF;
    padding: 10px;
    text-align: center;
  }

  p {
    padding: 10px;

    span {
      color: #C778DD;
    }
  }

  button {
    margin-left: 10px;
  }

}