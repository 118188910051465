.section-contacts {

  .contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 572px) {
      flex-direction: column;
      gap: 30px;

      .contact-me {
        width: 100%;
      }
    }

    .contacts-desc {
      flex-basis: 50%;
    }
  }
}