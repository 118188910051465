.my-facts-list {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 600px;
  @media (max-width: 729px) {
    justify-content: center;
    align-items: center;
  }

}