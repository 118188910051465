.section-about-me  {

  .about-me-title {
    width: 100%;
    margin-bottom: 30px;

    p {
      color: #FFFFFF;
      font-weight: 600;
    }
  }

  .about-me-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 800px) {
      flex-direction: column;
      gap: 30px;
    }

    .about-me-text {
      max-width: 500px;
    }

    .about-me-img {
     width: 300px;
      height: 500px;
    }
  }

}