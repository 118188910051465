.contacts-form {
  max-width: 569px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  input, textarea {
    border: 1px solid #ABB2BF;
    resize: none;
    padding: 10px;
    color: #ABB2BF;

    &:focus {
      border: 1px solid #C778DD;
    }
  }

  .form-user-info {
    display: flex;
    justify-content: space-between;

    @media (max-width: 548px) {
      flex-direction: column;
      row-gap: 20px;

      input {
        width: 100%;
      }
    }
  }

  #title {
    width: 100%;
  }

  #message {
    width: 100%;
    height: 120px;
    outline: none;
  }
}