.section-profile {
  display: flex;
  align-items: center;

  .profile-desc {
    max-width: 537px;

    h1 {
      font-size: 32px;
      font-weight: 600;
      line-height: 42px;
      color: #FFFFFF;
      margin-bottom: 30px;

      span {
        color: #C778DD;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #ABB2BF;
      margin-bottom: 30px;
    }

  }

  .profile-img {

    .portfolio-status {
      width: 100%;
      border: 1px solid #ABB2BF;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #ABB2BF;
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 8px 0 8px 30px;

      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: #C778DD;
      }
    }
  }

  @media screen and (max-width: 725px) {
    & {
      flex-direction: column;
      gap: 20px;
    }
  }
}