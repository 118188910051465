.quote-container {
  max-width: max-content;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .quote-text {
    padding: 32px;
    border: 1px solid #ABB2BF;
    text-align: center;

    h3:before, h3:after {
      content: url(../../assets/icons/quote.svg);
    }
  }

  .quote-author {
    padding: 16px;
    border: 1px solid #ABB2BF;
    align-self: flex-end;

    @media (max-width: 725px) {
      align-self: auto;
      text-align: center;
    }
  }
}

