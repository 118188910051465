.contact-me {
  border: 1px solid #ABB2BF;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    display: flex;
    align-items: center;
  }
}