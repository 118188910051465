.section-my-facts {

  h2 {
    margin-bottom: 50px;
  }

  .my-facts-box {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 729px) {
      flex-direction: column;
    }
  }

}