.home-page {

  .section-skills {

    .skills {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      @media (max-width: 734px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }

    }
  }
}