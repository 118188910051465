.logo {
  display: flex;
  align-items: center;
  gap: 8px;

  .logo-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    color: #FFFFFF;
  }
}