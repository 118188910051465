@import "./assets/styles/reset";
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600;700&display=swap');

body {
  background-color: #282C33;
  font-family: 'Fira Code', monospace;
  font-size: 16px;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  width: 100%;
}

.mb100 {
  margin-bottom: 100px !important;
}

.container {
  max-width: 1024px;
  padding: 0 10px;
  margin: 0 auto;
}

h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #FFFFFF;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
}

.section-title {
  margin-bottom: 30px;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    width: 150px;
    height: 1px;
    background: #C778DD;
    top: 50%;
    left: 120%;
  }
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ABB2BF;
}
.active-link {
  color: #C778DD;
}