footer {
  border-top: 1px solid #ABB2BF;
  padding: 30px 0;
  width: 100%;

  .section-footer {
    .section-footer-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .section-footer-desc {

        p {
          color: #FFFFFF;
          @media (max-width: 442px) {
            text-align: center;
          }
        }

        .section-footer-desc-title {
          display: flex;
          gap: 20px;
          align-items: center;
          margin-bottom: 20px;

          @media (max-width: 580px) {
            justify-content: center;
          }

          a {
            color: #ABB2BF;
          }
        }
      }

      .footer-nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h2 {
          font-weight: 500;
          font-size: 24px;
          line-height: 31px;
          color: #FFFFFF;
        }
      }

      @media (max-width: 580px) {
        flex-direction: column;
        gap: 20px;
      }
    }

    .footer-policy {
      text-align: center;
      color: #ABB2BF;
    }
  }

}