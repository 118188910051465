.skill-block {
  border: 1px solid #ABB2BF;

  .skill-block-title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding: 10px;
  }

  .skill-block-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ABB2BF;
    padding: 10px;
    max-width: 196px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}